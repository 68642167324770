<template>
  <licence-information
      v-on:commitChanges="save"
      v-model="activePerson.flyingData.medicals"
      v-bind:licenceData="activePerson.flyingData.medicals"
      licenceInformationType="medicals" :is-editable="isEditable"/>
</template>

<script>
import LicenceInformation from "@/components/shared/LicenceInformation";
import {mapActions, mapMutations, mapState} from "vuex";
import {PersonHttp} from "@/http/PersonHttp";
import {AuthHttp} from "@/http/AuthHttp";
import jwt from "jsonwebtoken";
import {isAuthorised} from "@/util/helpers";
export default {
  name: "MedicalPage",
  components: {LicenceInformation},
  computed: {
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id  || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
  methods: {
    ...mapMutations("app", ["setToast", "setLoggedIn", "setToken", "setLoggedInUser"]),
    ...mapActions("user", ["setFlyingStatus","setGroupMembership"]),
    async save() {
      try {
        await PersonHttp.updatePersonDetails(this.activePerson._id,this.activePerson);

        if (this.activePerson._id === this.loggedInPerson._id) {
          const res = await AuthHttp.updateToken()
          // get the decoded payload ignoring signature, no secretOrPrivateKey needed
          await this.setToken(res.token);
          const decoded = jwt.decode(res.token, {complete: true});
          // Update the Users Store
          await this.setLoggedInUser(decoded.payload)
          await this.setGroupMembership(decoded.payload.person.groupMembership)
          await this.setFlyingStatus()
        }
        this.setToast({color: 'success', text: 'Profile update successful', show: true});
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>