<template>
  <v-sheet class="surface">
    <v-data-table
        :headers="headers"
        :items="licenceData"
        no-results-text="No check details are currently held on your profile."
        no-data-text="No check details are currently held on your profile."
    >

      <template v-slot:item.type="{ item }">
        {{ getItemName(item._id) }}
      </template>
      <template v-slot:item.expiryDate="{ item }">
        {{
          licenceType === "LAPL(A)" && isRollingExpiryItem(getItemName(item._id)) ? "Rolling Currency" :
              itemExpires(item._id) ? $moment(item.expiryDate).format('DD MMM YYYY') : "Non Expiring"
        }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-icon :color="getItemStatusColor(item._id, item.expiryDate, licenceType)">
          {{ getItemStatusColor(item._id, item.expiryDate, licenceType) === 'red' ? 'mdi-close-circle': 'mdi-check-circle' }}</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <app-btn x-small fab color="secondary" v-if="isEditable" @click="remove(item)">
          <v-icon>mdi-delete</v-icon>
        </app-btn>
      </template>

      <template v-slot:footer>
        <v-divider class="mt-2 mb-4"/>
        <div class="px-6" v-if="isEditable">
          <v-form ref="formAmend">
            <v-layout class="" row wrap>
              <v-flex xs12 sm5 md6 lg5>
                <div class="caption">
                  {{ licenceInformationType.substring(0, licenceInformationType.length - 1).toUpperCase() }}
                </div>
                <div>
                  <v-select
                      outlined
                      dense
                      v-model="selectedItem"
                      :items="items"
                      item-text="name"
                      item-value="_id"
                      label="New"
                      clearable
                  ></v-select>
                </div>
              </v-flex>
              <v-flex xs12 sm5 md4 lg5 v-show="showDatePicker">
                <div class="caption px-2">Expiry Date</div>
                <div>
                  <app-date-picker label="Expiry Date" v-model="selectedExpiryDate"/>
                </div>
              </v-flex>
              <v-spacer/>
              <v-flex xs12 sm2 md2 lg2 class="justify-end">
                <div class="caption mx-3">Action</div>
                <div class="mx-3">
                  <app-btn x-small fab color="primary" class="mr-4" @click="add()"
                           :disabled="!newLicenceInformationFormComplete">
                    <v-icon>mdi-plus</v-icon>
                  </app-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import {mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import AppDatePicker from "@/components/app/DatePicker";
import {endOfDay, isAfter} from "date-fns";

export default {
  name: "LicenceInformation",
  components: {AppDatePicker, AppBtn},
  props: {
    licenceInformationType: {
      type: String
    },
    licenceData: {
      type: Array
    },
    licenceType: {
      type: String
    },
    isEditable: {
      type: Boolean
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.licenceInformationType.toUpperCase(),
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {text: 'Expiry Date', value: 'expiryDate'},
        {text: 'Status', value: 'status'},
        {text: 'Actions', value: 'actions'}
      ],
      selectedItem: null,
      showDatePicker: false,
      selectedExpiryDate: null, //new Date().toISOString().substr(0, 10),
      vuexAction: null,
      items: [],
      listOfItems: [],
      addNewDetailsForm: null,
      rollingExpiryItems:["SEP","SSEA"]
    }
  },
  async mounted() {
    if (this.licenceInformationType === 'ratings') {
      this.listOfItems = this.licenceEndorsementTypes.filter(e => e.type === 'rating')
    }
    if (this.licenceInformationType === 'certificates') {
      this.listOfItems = this.licenceEndorsementTypes.filter(e => e.type === 'certificate')
    }
    if (this.licenceInformationType === 'medicals') {
      this.listOfItems = this.medicals
    }
    this.items = this.listOfItems
  },

  computed: {
    ...mapState("licence", ["licenceEndorsementTypes"]),
    ...mapState("medicals", ["medicals"]),

    newLicenceInformationFormComplete() {
      // has user selected an item to add?
      if (this.selectedItem) {
        if (this.itemExpires(this.selectedItem)) {
          //expires
          if (this.selectedExpiryDate) {
            return true
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  watch: {
    selectedItem(newVal) {
      if (this.licenceInformationType === 'medicals') {
        this.showDatePicker = true;
        this.selectedExpiryDate = new Date().toISOString().substr(0, 10)
      } else {
        const selectedItemName = this.getItemName(newVal)

        if (this.isRollingExpiryItem(selectedItemName) && this.licenceType == "LAPL(A)") {
          this.showDatePicker = false
          this.selectedExpiryDate = new Date().toISOString().substr(0, 10)
        } else {
          if (this.itemExpires(newVal)) {
            this.showDatePicker = true
            this.selectedExpiryDate = new Date().toISOString().substr(0, 10)
          } else {
            this.showDatePicker = false
          }
        }
      }
    },
    licenceType(newVal) {
      if(newVal) {
        this.selectedItem = null
        this.showDatePicker = false
      }
    }

  },
  methods: {
    getItemName(val) {
      if (val) {
        const filteredItem = this.listOfItems.find(item => item._id === val)
        if (filteredItem) {
          return filteredItem.name
        } else {
          return 'Error: No Name found'
        }
      } else {
        return 'Error'
      }
    },
    getItemStatusColor(id, expiryDate, licenceType) {
      const isRollingExpiryItem = this.isRollingExpiryItem(this.getItemName(id))
      const itemExpires = this.itemExpires(id)

      if (isRollingExpiryItem && licenceType=== "LAPL(A)") return "green"
      switch (itemExpires) {
        case true:
            return isAfter(endOfDay(new Date(expiryDate)),new Date()) ? "green" : "red"
        case false:
          return "green"
        default:
          return "red"

      }
    },
    isRollingExpiryItem(itemName) {
      return this.rollingExpiryItems.some(v => itemName.includes(v))
    },
    itemExpires(val) {

      let filteredItem = this.listOfItems.find(item => item._id === val)
      // Check if expires is in the list of items
      if (typeof filteredItem !== 'undefined') {
        if ('expires' in filteredItem) {
          return filteredItem.expires
        } else {
          return true
        }
      }
    },

    add() {
      // Add entry

      // Format object to add depending if the item has an expiry date
      if (!this.itemExpires(this.selectedItem)) {
        this.licenceData.push({
          _id: this.selectedItem
        })
      } else {
        this.licenceData.push({
          _id: this.selectedItem,
          expiryDate: this.selectedExpiryDate
        })
        this.$emit('commitChanges')

        this.informationAmended = true
      }

      // remove added item from dropdown
      this.items = this.items.filter(item => item._id !== this.selectedItem)

      // Clear the form
      this.selectedExpiryDate = null
      this.selectedItem = null
    },
    remove(item) {

      // remove entry
      const index = this.licenceData.indexOf(item);
      this.licenceData.splice(index, 1);

      //Check if item is already in the list
      let itemExists = this.items.filter(item => item._id !== this.selectedItem)
      if (itemExists.length > 0) {
        // add entry back into dropdown list
        let itemToAddToDropdown = this.listOfItems.filter(i => i._id == item._id)
        this.items = [...this.items, ...itemToAddToDropdown]

        // Reorder alphabetically
        this.items.sort((a, b) => (a.name > b.name) ? 1 : -1)


      }
      this.$emit('commitChanges')

    },
  }
}
</script>

<style scoped>

</style>